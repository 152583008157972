<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatapp" dense>
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          class="capitalize"
          style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="success" text v-on="on">
              <v-icon color="success" size="25">mdi-bell-outline</v-icon>
              <v-badge
                :content="pharmaNotify"
                :value="pharmaNotify"
                bordered
                color="success"
                overlap
                style="margin: 5px 0px 0px 5px"
              ></v-badge>
            </v-btn>
          </template>
          <span>Request Response Notification</span>
        </v-tooltip>

        <v-btn color="primary" icon @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-tabs v-model="tabs" grow vm>
        <v-tab key="no">
          Un-Accepted &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {{ countLess.space }} {{ countLess.count }}
        </v-tab>
        <v-tab key="yes">
          Accepted &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {{ countMore.space }} {{ countMore.count }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item key="no">
          <v-data-table
            :headers="headers"
            :items="clientList"
            :items-per-page="10"
            :loading="loading"
            loader-height="1"
          >
            <template v-slot:item.name="{ item }">
              {{ item.user.salutations | capitalize }}
              {{ item.user.first_name | capitalize }}
              {{ item.user.mid_name | capitalize }}
              {{ item.user.last_name | capitalize }}
            </template>
            <template v-slot:item.mobile="{ item }">
              {{ item.user.mobile_phone }}
            </template>
            <template v-slot:item.totalCost="{ item }">
              {{ currency(item.totalCost) }}
            </template>

            <template v-slot:item.accept="{ item }">
              <v-icon :color="item.accept ? 'blue' : 'red'">
                {{ item.accept ? "mdi-check" : "mdi-close" }}
              </v-icon>
            </template>
            <template v-slot:item.updated="{ item }">
              {{ item.updated | myDating }}
            </template>
            <template v-slot:item.id="{ item }">
              <v-btn
                color="primary"
                text
                x-small
                @click.stop="
                  $set(prescDialog, item.medics_available_timesid, true)
                "
              >
                presciption
              </v-btn>
              <ClientPrescribe
                v-if="prescDialog[item.medics_available_timesid]"
                :myId="item.medics_available_timesid"
                :mySubId="item.subscriber_subsidiariesid"
                :name="
                  myName(
                    item.user.salutations,
                    item.user.first_name,
                    item.user.mid_name,
                    item.user.last_name
                  )
                "
                :prescDialog="prescDialog[item.medics_available_timesid]"
                @closePresc="closePresc"
              />

              <v-btn
                color="success"
                text
                x-small
                @click.stop="$set(acceptDialog, item.id, true)"
              >
                accept
              </v-btn>
              <ClientAccept
                v-if="acceptDialog[item.id]"
                :accept="item.accept"
                :acceptDialog="acceptDialog[item.id]"
                :myId="item.id"
                :name="
                  myName(
                    item.user.salutations,
                    item.user.first_name,
                    item.user.mid_name,
                    item.user.last_name
                  )
                "
                :search="item.medics_available_timesid"
                @closeAccept="closeAccept"
                @submitAccept="submitAccept"
              />
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item key="yes">
          <v-data-table
            :headers="headers"
            :items="acceptedClientList"
            :items-per-page="10"
            :loading="loading"
            loader-height="1"
          >
            <template v-slot:item.name="{ item }">
              {{ item.user.salutations | capitalize }}
              {{ item.user.first_name | capitalize }}
              {{ item.user.mid_name | capitalize }}
              {{ item.user.last_name | capitalize }}
            </template>
            <template v-slot:item.mobile="{ item }">
              {{ item.user.mobile_phone }}
            </template>
            <template v-slot:item.totalCost="{ item }">
              {{ currency(item.totalCost) }}
            </template>

            <template v-slot:item.accept="{ item }">
              <v-icon :color="item.accept ? 'blue' : 'red'">
                {{ item.accept ? "mdi-check" : "mdi-close" }}
              </v-icon>
            </template>
            <template v-slot:item.updated="{ item }">
              {{ item.updated | myDating }}
            </template>
            <template v-slot:item.id="{ item }">
              <v-btn
                color="primary"
                text
                x-small
                @click.stop="
                  $set(prescDialog, item.medics_available_timesid, true)
                "
              >
                presciption
              </v-btn>
              <ClientPrescribe
                v-if="prescDialog[item.medics_available_timesid]"
                :myId="item.medics_available_timesid"
                :mySubId="item.subscriber_subsidiariesid"
                :name="
                  myName(
                    item.user.salutations,
                    item.user.first_name,
                    item.user.mid_name,
                    item.user.last_name
                  )
                "
                :prescDialog="prescDialog[item.medics_available_timesid]"
                @closePresc="closePresc"
              />

              <v-btn
                color="success"
                text
                x-small
                @click.stop="$set(acceptDialog, item.id, true)"
              >
                accept
              </v-btn>
              <ClientAccept
                v-if="acceptDialog[item.id]"
                :accept="item.accept"
                :acceptDialog="acceptDialog[item.id]"
                :myId="item.id"
                :name="
                  myName(
                    item.user.salutations,
                    item.user.first_name,
                    item.user.mid_name,
                    item.user.last_name
                  )
                "
                :search="item.medics_available_timesid"
                @closeAccept="closeAccept"
                @submitAccept="submitAccept"
              />
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import Notifications from "../../mixins/notifications";
import ClientPrescribe from "../../components/client/precribe.vue";
import utils from "../../mixins/utils";
import ClientAccept from "../../components/client/accept.vue";

export default {
  components: { ClientAccept, ClientPrescribe },
  mixins: [utils, Notifications, WinScroll("position")],
  data() {
    return {
      tabs: null,
      loading: false,
      viewDialog: {},
      prescDialog: {},
      acceptDialog: {},
      crumbs: [
        {
          text: "Dashboard",
          disabled: false,
          to: { name: "pharma.dash" },
        },
        {
          text: "Purchases",
          disabled: true,
          to: { name: "pharma.purchased" },
        },
      ],
      clientList: [],
      acceptedClientList: [],
      countLess: {
        count: "",
        space: "",
      },
      countMore: {
        count: "",
        space: "",
      },
      headers: [
        { text: "client", value: "name" },
        { text: "mobile phone", value: "mobile" },
        { text: "offer cost", value: "totalCost" },
        { text: "accepted", value: "accept", align: "center" },
        { text: "updated", value: "updated" },
        { text: "action", value: "id", align: "center" },
      ],
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.clients();
    this.acceptedClients();
  },
  methods: {
    clients() {
      this.clientList = [];
      this.acceptedClientList = [];
      this.loading = true;
      let self = this;
      Restful.medics.treatment.transact.prospect.prescribe
        .client(false)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.clientList = response.data;
            this.countLess.count = response.data.length;
          } else {
            this.countLess.count = "";
          }
          // this.pharmaClients();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.countMore.count = "";
          self.loading = false;
        });
    },
    acceptedClients() {
      this.clientList = [];
      this.acceptedClientList = [];
      this.loading = true;
      let self = this;
      Restful.medics.treatment.transact.prospect.prescribe
        .client(true)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.acceptedClientList = response.data;
            this.countMore.count = response.data.length;
          } else {
            this.countMore.count = "";
          }
          // this.pharmaClients();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.countMore.count = "";
          self.loading = false;
        });
    },
    refresh() {
      this.clients();
      this.acceptedClients();
    },
    menulink() {
      this.$emit("sidebar");
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeAccept(p) {
      this.$set(this.acceptDialog, p.id, p.state);
    },
    submitAccept(p) {
      let self = this;
      Restful.medics.treatment.transact.prospect.prescribe
        .accept(p.id, p.data)
        .then((response) => {
          console.log(response);
          this.clients();
          this.acceptedClients();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
      this.$set(this.acceptDialog, p.id, p.state);
    },
    myName(sa, fa, m, l) {
      let name = sa + " " + fa + " " + m + " " + l;
      return name;
    },
    closePresc(p) {
      this.$set(this.prescDialog, p.id, p.state);
    },
  },
};
</script>
