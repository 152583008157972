<template>
  <v-dialog v-model="acceptDialog" persistent max-width="500">
    <v-card>
      <v-card-title>accept sale offer</v-card-title>
      <v-card-text>
        <!-- Are you sure you want to permanently remove this item? => {{ name }} =>
        {{ myId }} -->
        <p>
          Client: <b>{{ name | capitalize }}</b>
        </p>
        <v-switch
          v-model="create.accepted"
          :label="`Accept: ${create.accepted.toString()}`"
        ></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              accept
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import Restful from "@/services/RestFul";
//   import moment from "moment";
export default {
  props: {
    acceptDialog: Boolean,
    myId: { type: Number },
    name: { type: String },
    search: { type: Number },
    accept: { type: Boolean },
  },
  data() {
    return {
      myData: {},
      create: {
        accepted: this.accept,
        service: null,
        description: null,
      },
    };
  },
  created() {},
  methods: {
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeAccept", payload);
    },
    submit() {
      let accepted = this.create.accepted;
      let data = { accepted };
      let payload = { id: this.myId, state: false, data: data };
      this.$emit("submitAccept", payload);
    },
  },
};
</script>
