<template>
  <v-dialog v-model="prescDialog" persistent>
    <v-card>
      <v-card-title>prescription on {{ name }}'s sale offer</v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="view" :items-per-page="5">
          <template v-slot:item.medical.brand="{ item }">
            {{ item.medical.brand }}
          </template>

          <template v-slot:item.medical.price="{ item }">
            {{ currency(item.medical.price) }}
          </template>
          <template v-slot:item.quantity="{ item }">
            {{ comma(item.quantity) }}
          </template>
          <template v-slot:item.medical.total_cost="{ item }">
            {{ currency(item.medical.total_cost) }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../mixins/utils.js";

export default {
  props: {
    prescDialog: Boolean,
    myId: { type: Number },
    mySubId: { type: Number },
    name: { type: String },
  },
  mixins: [Utils],
  data() {
    return {
      view: [],
      headers: [
        { text: "category", value: "medical.medicines" },
        { text: "brand", value: "medical.brand_name" },
        { text: "brand description", value: "medical.brand_description" },
        { text: "manufacturer", value: "medical.manufacturer" },
        { text: "formulations", value: "medical.formulations" },
        { text: "quantity", value: "quantity" },
        { text: "price", value: "medical.price" },
        { text: "sub total", value: "medical.total_cost" },

        // { text: "fomulation", value: "medical" },
        // { text: "dose form", value: "medical" },
        // { text: "strength", value: "medical" },
        // { text: "refills", value: "medical" },
      ],
    };
  },
  created() {
    this.detail();
  },
  methods: {
    detail() {
      let subscriberSubsidiariesid = parseInt(this.mySubId);
      let medics_available_timesid = parseInt(this.myId);
      let data = { subscriberSubsidiariesid, medics_available_timesid };
      console.log("data", data);
      Restful.medics.treatment.transact.prospect.prescribe
        .detailMedic(data)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.view = response.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closePresc", payload);
    },
    // submit() {
    //   let payload = { id: this.myId, state: false };
    //   this.$emit("submitRemove", payload);
    // },
  },
};
</script>
